import { Injectable } from "@angular/core";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { Observable, of, throwError } from "rxjs";
import { ClassContent, GroupContent } from "../types/class-content";
import { StorageCache } from "../../core/storage-cache";
import { Organization } from "../types/organization";
import { ClassAnnouncement } from "../types/class-announcement";
import { Course } from "../types/course";
import { map as rxJsMap, mergeMap, tap } from "rxjs/operators";
import { LANGUAGE_DEFAULT } from "../../core/locale";
import { ClassWordListSetting } from "../types/word-list-reference";
import { AffiliationModelService } from "./affiliation-model.service";
import { StudentData, StudentDatas, StudentExtras } from "../types/student-data";
import { AjaxResponse } from "rxjs/ajax";
import { ClassLevelTestSetting, ClassLevelTestSettings } from "../types/level-test-setting";
import { assign, compact, get, isEmpty, isString, join, map, split, trim } from "lodash-es";

@Injectable()
export class ClassIdentityModelService {
    private studentClassesCache = new StorageCache<ClassContent[]>("StudentClasses");
    private studentGroupsCache = new StorageCache<ClassContent[]>("StudentGroupsCache");
    private studentOrganizationCache = new StorageCache<Organization>("StudentOrganization");
    private classContentCache = new StorageCache<ClassContent>("ClassContent");
    private groupContentCache = new StorageCache<ClassContent>("GroupContent");
    private classCourseContentCache = new StorageCache<Course[]>("ClassCourse");


    constructor(private connection: ConnectionFactoryService) {
    }

    uploadLogo(formData: FormData, params: object = {}): Observable<AjaxResponse<void>> {
        let ajaxSettings = {
            method: "POST",
            headers: {
                "Cache-Control": "no-store",
                "Pragma": "no-cache"
            },
            body: formData,
            crossDomain: false
        };

        return this.connection
            .create()
            .setPath("/api/bridge/upload/logo")
            .ajax(ajaxSettings)
            .pipe(tap(() => {
                this.destroyStudentOrganizationCache();
            }));
    }

    addAnnouncement(classId: number, announcement: string, params: { [key: string]: any }): Observable<any> {
        if (!classId) {
            return throwError("classID required");
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/announcement`)
            .post(params, announcement);
    }

    getAnnouncements(classId: number, params: object): Observable<ClassAnnouncement[]> {
        if (!classId) {
            return throwError("classID required");
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/announcement`)
            .get(params);
    }

    getAnnouncementsCount(classId: number, params: object): Observable<number> {
        if (!classId) {
            return throwError("classID required");
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/announcement/count`)
            .get(params, undefined, ConnectionFactoryService.SERVICE_VERSION.v1);
    }

    deleteAnnouncement(classId: number, announcementId: number, params: object): Observable<any> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/announcement/${announcementId}`)
            .delete(params);
    }

    updateAnnouncement(classId: number, announcementId: number, params): Observable<any> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/announcement/${announcementId}`)
            .put(params);
    }

    addSchool(name: string, partnerId: number): Observable<ClassContent> {
        if (!name) {
            return throwError("name required");
        }

        return this.connection
            .service("bridge")
            .setPath("/identity/organization")
            .post({
                name: name,
                partnerId: partnerId
            }, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
    }

    getOrganizations(params: object): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath("/identity/organization")
            .get(params);
    }

    addGroup(name: string,
             classID: number): Observable<ClassContent> {
        if (!name) {
            return throwError("name required");
        }
        return this.connection
            .service("bridge")
            .setPath("/identity/group")
            .post({
                name: name,
                classID: classID
            }, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
    }

    getClasses(organizationId: number,
               params: object = {}): Observable<ClassContent> {
        if (!organizationId) {
            return throwError("organizationID required");
        }
        return this.connection
            .service("bridge")
            .setPath("/identity/class")
            .get(assign({organizationID: organizationId}, params));
    }

    addClass(organizationId: number,
             params: object = {}): Observable<ClassContent> {
        if (!organizationId) {
            return throwError("organizationID required");
        }
        return this.connection
            .service("bridge")
            .setPath("/identity/class")
            .post(assign({organizationID: organizationId}, params));
    }

    updateSchool(name: string,
                 organizationId: number): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}`)
            .put({
                name: name
            });
    }

    updateGroup(name: string,
                groupId: number): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/group/${groupId}`)
            .put({
                name: name
            });
    }

    updateClass(name: string,
                classId: number): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}`)
            .put({
                name: name
            });
    }

    deleteSchool(organizationId: number): Observable<ClassContent> {
        if (!organizationId) {
            return throwError("organizationId required");
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}`)
            .delete({});

    }

    deleteClass(classId: number): Observable<ClassContent> {
        if (!classId) {
            return throwError("classId required");
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}`)
            .delete({});

    }

    deleteGroup(groupId: number): Observable<ClassContent> {
        if (!groupId) {
            return throwError("groupId required");
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/group/${groupId}`)
            .delete({});
    }

    addTeacherToOrganization(organizationId: number,
                             emails: string,
                             invitationMessage: string,
                             sendEmail: boolean = true): Observable<ClassContent> {
        let message = trim(invitationMessage || "");

        return this.connection
            .service("bridge")
            .setPath(`/identity/invitation/organization/${organizationId}`)
            .post({
                emails: this.sanitizeEmails(emails),
                sendEmail: sendEmail || message.length,
                emailLanguage: LANGUAGE_DEFAULT,
                invitationMessage: (message && message.length) ? message : undefined
            }, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
    }

    addTeacherToClass(classId: number,
                      emails: string,
                      invitationMessage: string): Observable<ClassContent> {
        let message = trim(invitationMessage || "");

        return this.connection
            .service("bridge")
            .setPath(`/identity/invitation/class/${classId}/teacher`)
            .post({
                emails: this.sanitizeEmails(emails),
                sendEmail: true,
                emailLanguage: LANGUAGE_DEFAULT,
                invitationMessage: (message && message.length) ? message : undefined
            }, undefined, ConnectionFactoryService.SERVICE_VERSION.v2);
    }

    addTeacherToClassBulk(classId: number, body: object[]): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/teacher`)
            .put(undefined, body, ConnectionFactoryService.SERVICE_VERSION.v1);
    }

    addToClass(accountId: number, classId: number, groupId?: number): Observable<any> {
        if (!classId || !accountId) {
            return throwError("error in adding to class " + classId);
        }

        this.clearClassCache();
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/student/${accountId}`)
            .post()
            .pipe(
                mergeMap(() => {
                    if (groupId) {
                        return this.addToGroup(accountId, groupId);
                    }
                    return of(true);
                })
            );
    }

    addToGroup(accountId: number, groupId?: number): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/group/${groupId}/account/${accountId}`)
            .post();
    }

    addStudentsToGroup(accountIds: string, groupId?: number): Observable<void> {
        if (!groupId) {
            return throwError("error in adding to group " + accountIds);
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/group/${groupId}/student`)
            .post({accountIDs: accountIds});
    }

    removeStudentsFromGroup(accountIds: string, groupId?: number): Observable<void> {
        if (!groupId) {
            return throwError("error in removing from group " + accountIds);
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/group/${groupId}/student`)
            .delete({accountIDs: accountIds});
    }

    addStudentsToClass(params: object = {}, classId?: number): Observable<void> {
        if (!classId) {
            return throwError("error in adding student to class");
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/invitation/class/${classId}/student`)
            .post(this.normalizeEmails(params));
    }

    updateOrganizationTeachers(organizationId: number, params: object): Observable<void> {
        if (!organizationId) {
            return throwError("error updating organization teacher");
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}/teacher`)
            .put(params);
    }

    addOrganizationCourses(organizationId: number, params: object): Observable<void> {
        if (!organizationId) {
            return throwError("error adding organization courses");
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}/course`)
            .post(params);
    }

    private normalizeEmails(params: object): object {
        let emails = get(params, "emails");
        if (isEmpty(emails)) {
            return params;
        }
        return assign({}, params, {emails: this.sanitizeEmails(emails)});
    }

    private sanitizeEmails(emails: string): string {
        return join(compact(split(emails, /[,;\s]/)));
    }

    removeStudentsFromClass(accountIds: string, classId?: number): Observable<void> {
        if (!classId) {
            return throwError("error in removing from class " + accountIds);
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/student`)
            .delete({accountIDs: accountIds});
    }

    removeStudentsFromOrganization(accountIds: string, organizationId: number): Observable<void> {
        if (!organizationId) {
            return throwError("error in removing from organization " + accountIds);
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}/students`)
            .delete({accountIDs: accountIds});
    }

    removeTeachersFromOrganization(organizationId: number, params: object): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}/teacher`)
            .delete(params);
    }

    removeTeachersFromClass(accountIds: string, classId?: number): Observable<void> {
        if (!classId) {
            return throwError("error in removing teachers from class " + accountIds);
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/teacher`)
            .delete({accountIDs: accountIds});
    }

    removeInvitationFromClass(emails: string, classId?: number): Observable<void> {
        if (!classId) {
            return throwError("error in removing invitations from class " + emails);
        }
        emails = this.sanitizeEmails(emails);
        return this.connection
            .service("bridge")
            .setPath(`/identity/invitation/class/${classId}`)
            .delete({emails: emails});
    }

    removeInvitationFromOrganization(emails: string, organizationId?: number): Observable<void> {
        if (!organizationId) {
            return throwError("error in removing invitations from class " + emails);
        }
        emails = this.sanitizeEmails(emails);
        return this.connection
            .service("bridge")
            .setPath(`/identity/invitation/organization/${organizationId}`)
            .delete({emails: emails});
    }

    clearClassCache(): void {
        this.studentClassesCache.destroy();
        this.studentGroupsCache.destroy();
        this.classContentCache.destroy();
    }

    getStudentClasses(accountId: number,
                      expiration: number = ConnectionFactoryService.CACHE_LIFETIME.classdata): Observable<ClassContent[]> {
        return this.studentClassesCache
            .getCache({accountId: accountId}, () => {
                return this.connection
                    .service("bridge")
                    .setPath(`/identity/class/student/${accountId}`)
                    .get({active: true});
            }, expiration);
    }

    getStudentGroups(accountId: number,
                     expiration: number = ConnectionFactoryService.CACHE_LIFETIME.classdata): Observable<ClassContent[]> {
        return this.studentGroupsCache
            .getCache({accountId: accountId}, () => {
                return this.connection
                    .service("bridge")
                    .setPath(`/identity/group/student/${accountId}`)
                    .get()
                    .pipe(
                        rxJsMap(groupContent => map(groupContent, "group"))
                    );
            }, expiration);
    }

    updateStudent(affiliationType: string, affiliationId: number, accountId: number, params = {}): Observable<void> {
        if (!affiliationId || !accountId) {
            return throwError("error in updating student status");
        }

        return this.connection
            .service("bridge")
            .setPath(`/identity/${affiliationType}/${affiliationId}/student/${accountId}`)
            .put(params);
    }

    getOrganizationById(orgId: number,
                        expiration: number = ConnectionFactoryService.CACHE_LIFETIME.classdata): Observable<Organization> {
        return this.studentOrganizationCache
            .getCache({organizationID: orgId}, () => {
                return this.connection
                    .service("bridge")
                    .setPath(`/identity/organization/${orgId}`)
                    .get();
            }, expiration);
    }

    getDefaultClass(accountId: number): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/current/account/${accountId}`)
            .get();
    }

    getClassById(classId: number): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}`)
            .get();
    }

    getClassList(params: object): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class`)
            .get(params);
    }

    getClassLatestData(classId: number): Observable<ClassContent> {
        const cacheKey = assign({}, {classId: classId});
        this.classContentCache.deleteCache(cacheKey);
        return this.getClass(classId);
    }

    getClass(classId: number, expiration: number = ConnectionFactoryService.CACHE_LIFETIME.classdata): Observable<ClassContent> {
        return this.classContentCache.getCache(assign({}, {classId: classId}), () => {
            return this.getClassById(classId);
        }, expiration);
    }

    getGroupById(groupId: number): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/group/${groupId}`)
            .get();
    }

    getGroup(groupId: number, expiration: number = ConnectionFactoryService.CACHE_LIFETIME.classdata): Observable<ClassContent> {
        return this.groupContentCache.getCache(assign({}, {groupId: groupId}), () => {
            return this.getGroupById(groupId);
        }, expiration);
    }

    getClassAnnouncement(classId: number): Observable<ClassAnnouncement[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/announcement`)
            .get();
    }

    getAffiliationActivityIds(affiliationId: number, params: object = {}, mode: string = AffiliationModelService.MODE_CLASS): Observable<number[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/${mode}/${affiliationId}/assessment/activity/ids`)
            .get(params);
    }

    getClassCourses(classId: number, params: object = {}, expiration: number = ConnectionFactoryService.CACHE_LIFETIME.progress): Observable<Course[]> {
        return this.classCourseContentCache.getCache(assign({}, params, {classId}), () => {
            return this.getRawClassCourses(classId, params);
        }, expiration);
    }

    getRawClassCourses(classId: number, params: object = {}): Observable<Course[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/courses`)
            .get(params);
    }

    getClassStudents(classId: number): Observable<Record<string, number>> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/student/added`)
            .get();
    }

    getStudentData(accountId: number): Observable<StudentData[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/studentdata/account/${accountId}`)
            .get();
    }

    getStudentExtras(classId: number, accountIds: number[] | string): Observable<StudentExtras> {
        if (isEmpty(accountIds) || !classId) {
            return of(undefined);
        }
        const accountIdsParam = isString(accountIds) ? accountIds : accountIds.join(",");
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/studentExtras/${classId}`)
            .get({accountIDs: accountIdsParam});
    }

    getStudentDataByOrg(orgId: number, accountIds: number[]): Observable<StudentDatas> {
        if (isEmpty(accountIds) || !orgId) {
            return of(undefined);
        }
        let accountIdsParam = accountIds.join(",");
        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${orgId}/studentdata?`)
            .get({accountIDs: accountIdsParam});
    }

    updateStudentTestDue(classId: number, accountId: number, type: string, dueDate: string): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/studentExtras/${classId}/account/${accountId}/testDue/${type}`)
            .post({dueDate});
    }

    updateStudentData(organizationId: number, accountId: number, params: object): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}/studentdata/${accountId}`)
            .post(params);
    }

    deleteStudentClasses(options: object = {}): void {
        this.studentClassesCache.destroy();
    }

    destroyClassCache(): void {
        this.classContentCache.destroy();
    }

    destroyGroupCache(): void {
        this.groupContentCache.destroy();
    }

    destroyStudentOrganizationCache(): void {
        this.studentOrganizationCache.destroy();
    }

    updateCurriculumClass(classId: number, parameters: object = {}): Observable<ClassContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}`)
            .put(parameters);
    }

    updateCurriculumGroup(groupId: number, parameters: object = {}): Observable<GroupContent> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/group/${groupId}`)
            .put(parameters);
    }

    // @FIXME return type
    updateClassWordListSetting(classId: number, classWordLists: ClassWordListSetting): Observable<any> {
        if (!classId) {
            return of(undefined);
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/wordlist`)
            .put();
    }

    // @FIXME return type
    deleteClassWordListSetting(classId: number): Observable<any> {
        if (!classId) {
            return of(undefined);
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/wordlist`)
            .delete();
    }

    getLevelTestSetting(classId: number): Observable<ClassLevelTestSettings> {
        if (!classId) {
            return of(undefined);
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/leveltest/setting`)
            .get(undefined, undefined, ConnectionFactoryService.SERVICE_VERSION.v1);
    }

    putLevelTestSetting(classId: number, classLevelTestSetting: ClassLevelTestSetting): Observable<void> {
        if (!classId) {
            return of(undefined);
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/leveltest/setting`)
            .put({}, classLevelTestSetting, ConnectionFactoryService.SERVICE_VERSION.v1);
    }

    deleteLevelTestSetting(classId: number, classLevelTestId: number): Observable<void> {
        if (!classId) {
            return of(undefined);
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/${classId}/leveltest/${classLevelTestId}`)
            .delete(undefined, undefined, ConnectionFactoryService.SERVICE_VERSION.v1);
    }

    getClassByIds(classIds: string, params?: object): Observable<ClassContent[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/class/classes`)
            .get(assign({}, {
                classIDs: classIds
            }, params));
    }
}
