import { SessionCallChannel } from "../session-call-channel";
import { SessionCallTypeId } from "../session-call-type";
import { WebRtcVendor } from "../session-communication-tool";
import { Session } from "../session";
import { ActivityV1 } from "../content/activity-v1";
import { SessionV4 } from "./session-v4";
import { SessionType } from "./session-type";
import { SessionV3 } from "./session-v3";
import {
    ROUTE_AGORA_MEETING,
    ROUTE_MEETING_ROOM,
    ROUTE_RTC_CLASSROOM
} from "../../../pwa-v2-landing-app/routes/routes";
import { AccountIdentity } from "../../../core/identity.service";
import { LANGUAGE_DEFAULT } from "../../../core/locale";
import { DateFnsConfig, fnsFormat } from "../../../core/date-fns-util";
import { addDays } from "date-fns";

export class SessionUtils {
    static getCallDetails(session?: Session | SessionV3 | SessionV4,
                          callChannel?: SessionCallChannel,
                          callTypeId?: SessionCallTypeId) {
        // @ts-ignore @FIXME: Why does GET /Session v3 response not have sessionCallTypeID?
        const sessionCallTypeId = session?.sessionCallTypeId || callTypeId;
        const sessionCallChannel = session?.callChannel || callChannel;
        return { sessionCallTypeId, sessionCallChannel };
    }

    static getWebRtcVendorByCommunicationTool(communicationTool: SessionCallChannel) {
        switch (communicationTool) {
            case SessionCallChannel.SKYPE:
                return SessionCallChannel.SKYPE;
            case SessionCallChannel.ZOOM:
                return SessionCallChannel.ZOOM;
            case SessionCallChannel.WEB_RTC:
                return WebRtcVendor.TWILIO;
            default:
                return undefined;
        }
    }

    static getCallChannelByCommunicationTool(communicationTool): SessionCallChannel {
        const WEBRTC_VARIATION = "webRTC";
        switch (communicationTool) {
            case SessionCallChannel.CHAT_WIZARD_MIMI:
                return SessionCallChannel.CHAT_WIZARD_MIMI;
            case SessionCallChannel.CHAT_WIZARD:
                return SessionCallChannel.CHAT_WIZARD;
            case SessionCallChannel.CHAT:
                return SessionCallChannel.CHAT;
            case SessionCallChannel.SKYPE:
                return SessionCallChannel.SKYPE;
            case SessionCallChannel.ZOOM:
                return SessionCallChannel.ZOOM;
            case SessionCallChannel.WEB_RTC:
                return SessionCallChannel.WEB_RTC;
            case WEBRTC_VARIATION:
                return SessionCallChannel.WEB_RTC;
            default:
                return undefined;
        }
    }

    static getCallTypeIdByCommunicationTool(communicationTool: SessionCallChannel): SessionCallTypeId {
        switch (communicationTool) {
            case SessionCallChannel.CHAT_WIZARD_MIMI:
                return SessionCallTypeId.CHAT_WIZARD_MIMI;
            case SessionCallChannel.CHAT_WIZARD:
                return SessionCallTypeId.CHAT_WIZARD;
            case SessionCallChannel.CHAT:
                return SessionCallTypeId.CHAT;
            case SessionCallChannel.SKYPE:
                return SessionCallTypeId.SKYPE;
            case SessionCallChannel.ZOOM:
                return SessionCallTypeId.ZOOM;
            case SessionCallChannel.WEB_RTC:
                return SessionCallTypeId.WEB_RTC;
            default:
                return undefined;
        }
    }

    static isSkypeSession(session?: Session | SessionV3 | SessionV4,
                          callChannel?: SessionCallChannel,
                          callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId, sessionCallChannel } = this.getCallDetails(session, callChannel, callTypeId);
        if (!sessionCallTypeId && !sessionCallChannel) {
            return !session?.isWebrtc
                || (session?.webrtcVendor && session?.webrtcVendor == SessionCallChannel.SKYPE);
        }
        return (sessionCallChannel == SessionCallChannel.SKYPE)
            || (sessionCallTypeId == SessionCallTypeId.SKYPE);
    }

    static isZoomSession(session?: Session | SessionV3 | SessionV4,
                         callChannel?: SessionCallChannel,
                         callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId, sessionCallChannel } = this.getCallDetails(session, callChannel, callTypeId);
        if (!sessionCallTypeId && !sessionCallChannel) {
            if (session?.webrtcVendor) {
                return session?.webrtcVendor == SessionCallChannel.ZOOM;
            }
            return session?.isWebrtc;
        }
        return (sessionCallChannel == SessionCallChannel.ZOOM)
            || (sessionCallTypeId == SessionCallTypeId.ZOOM);
    };

    static isMicrosoftTeamsSession(session?: Session | SessionV3 | SessionV4,
                                   callChannel?: SessionCallChannel,
                                   callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId, sessionCallChannel } = this.getCallDetails(session, callChannel, callTypeId);
        if (!sessionCallTypeId && !sessionCallChannel) {
            if (session?.webrtcVendor) {
                return session?.webrtcVendor == SessionCallChannel.TEAMS;
            }
            return session?.isWebrtc;
        }
        return (sessionCallChannel == SessionCallChannel.TEAMS)
            || (sessionCallTypeId == SessionCallTypeId.TEAMS);
    };

    static isAgoraSession(session?: Session | SessionV3 | SessionV4,
                          callChannel?: SessionCallChannel,
                          callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId } = this.getCallDetails(session, callChannel, callTypeId);
        return sessionCallTypeId == SessionCallTypeId.AGORA;
    };

    static isTwilioSession(session?: Session | SessionV3 | SessionV4,
                           callChannel?: SessionCallChannel,
                           callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId } = this.getCallDetails(session, callChannel, callTypeId);
        return sessionCallTypeId == SessionCallTypeId.WEB_RTC;
    };

    static isWebRtcSession(session?: Session | SessionV3 | SessionV4,
                           callChannel?: SessionCallChannel,
                           callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId, sessionCallChannel } = this.getCallDetails(session, callChannel, callTypeId);
        if (!sessionCallTypeId && !sessionCallChannel) {
            if (session?.webrtcVendor) {
                return session?.webrtcVendor == WebRtcVendor.TWILIO;
            }
            return session?.isWebrtc;
        }
        return (sessionCallChannel == SessionCallChannel.WEB_RTC)
            || (sessionCallTypeId == SessionCallTypeId.WEB_RTC);
    }

    // @INFO: ChatSession =>
    // @INFO: Details: Tutor speaks as himself. Tutor does not appear as MiMi. Chat-Bot is disabled.
    // @INFO: tutor can not turn Chat-Bot on and can not toggle impersonation. Plain chat in between
    // @INFO: the student and the tutor.
    // ------
    // @INFO: Chat-Bot: Disabled;
    // @INFO: Tutor-Impersonation: Disabled;
    // @INFO: Tutor=Impersonation-Toggle: Disabled;
    static isChatSession(session?: Session | SessionV3 | SessionV4,
                         callChannel?: SessionCallChannel,
                         callTypeId?: SessionCallTypeId) {
        const { sessionCallTypeId, sessionCallChannel } = this.getCallDetails(session, callChannel, callTypeId);
        return (sessionCallChannel == SessionCallChannel.CHAT)
            || (sessionCallTypeId == SessionCallTypeId.CHAT);
    }

    // @INFO: ChatWizardSession =>
    // @INFO: Details: Tutor starts to speak under the Chat-Bot icon and name. Chat-Bot is disabled.
    // @INFO: tutor can turn off Chat-Bot or toggle impersonation anytime.
    // ------
    // @INFO: Chat-Bot: Disabled;
    // @INFO: Tutor-Impersonation: Enabled;
    // @INFO: Tutor=Impersonation-Toggle: Enabled;
    static isChatWizardSession(session?: Session | SessionV3 | SessionV4,
                               callChannel?: SessionCallChannel,
                               callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId, sessionCallChannel } = this.getCallDetails(session, callChannel, callTypeId);
        return (sessionCallChannel == SessionCallChannel.CHAT_WIZARD)
            || (sessionCallTypeId == SessionCallTypeId.CHAT_WIZARD);
    }

    // @INFO: ChatWizardMimiSession =>
    // @INFO: Details: Tutor starts to speak under the Chat-Bot icon and name. Chat-Bot is enabled.
    // @INFO: tutor can turn off MiMi or toggle impersonation anytime.
    // ------
    // @INFO: Chat-Bot: Enabled;
    // @INFO: Tutor-Impersonation: Enabled;
    // @INFO: Tutor=Impersonation-Toggle: Enabled;
    static isChatWizardMimiSession(session?: Session | SessionV3 | SessionV4,
                                   callChannel?: SessionCallChannel,
                                   callTypeId?: SessionCallTypeId): boolean {
        const { sessionCallTypeId, sessionCallChannel } = this.getCallDetails(session, callChannel, callTypeId);
        return (sessionCallChannel == SessionCallChannel.CHAT_WIZARD_MIMI)
            || (sessionCallTypeId == SessionCallTypeId.CHAT_WIZARD_MIMI);
    }

    static isAnyChatSession(session?: Session | SessionV3 | SessionV4,
                            callChannel?: SessionCallChannel,
                            callTypeId?: SessionCallTypeId): boolean {
        return this.isChatSession(session, callChannel, callTypeId)
            || this.isChatWizardSession(session, callChannel, callTypeId)
            || this.isChatWizardMimiSession(session, callChannel, callTypeId);
    }

    static isAnyChatWizardSession(session?: Session | SessionV3 | SessionV4,
                                  callChannel?: SessionCallChannel,
                                  callTypeId?: SessionCallTypeId): boolean {
        return this.isChatWizardSession(session, callChannel, callTypeId)
            || this.isChatWizardMimiSession(session, callChannel, callTypeId);
    }

    static isAnyVideoCallSession(session?: Session | SessionV3 | SessionV4,
                                 callChannel?: SessionCallChannel,
                                 callTypeId?: SessionCallTypeId): boolean {
        return (
            this.isWebRtcSession(session, callChannel, callTypeId)
            || this.isZoomSession(session, callChannel, callTypeId)
            || this.isMicrosoftTeamsSession(session, callChannel, callTypeId)
            || this.isAgoraSession(session, callChannel, callTypeId)
        ) && !this.isAnyChatSession(session, callChannel, callTypeId);
    }

    static filterNonChatSessions(sessions): Session[] | SessionV4[] | undefined {
        return sessions.filter((session: Session | SessionV3 | SessionV4) => !this.isAnyChatSession(session));
    }

    static filterChatSessions(sessions): Session[] | undefined {
        return sessions.filter((session: Session) => this.isAnyChatSession(session));
    }

    static buildSkypeUrl(skypeId: string): string {
        return `skype:${skypeId}?chat`;
    }

    static getBumperTimeBySessionType(session: {callChannel?: SessionCallChannel, realTime?: boolean}): number {
        const FIVE_MIN_IN_MS = 5 * 60 * 1000;
        const THIRTY_MIN_IN_MS = 30 * 60 * 1000;
        // @INFO: For realtime chat wizard chats we want to keep the lesson card there for some time.
        return (SessionUtils.isAnyChatSession(undefined, session?.callChannel) && session?.realTime)
            ? THIRTY_MIN_IN_MS
            : FIVE_MIN_IN_MS;
    }

    static getAccountIds(session: { accountIds?: number[], accountIDs?: number[], accountID?: number }): number[] {
        return (session?.accountIds ?? session?.accountIDs ?? [session?.accountID]).filter(item => !!item);
    }

    static getAccountId(session: SessionV4): number | undefined {
        return this.getAccountIds(session)?.[0];
    }

    static getLocalizedDateFormat(session: Session | SessionV3 | SessionV4,
                                  identity?: AccountIdentity,
                                  withTimeZone: boolean = true,
                                  withYearInfo: boolean = false): string {
        if (!session) {
            return "";
        }
        return this.getLocalizedDateFormatByTimestamp(new Date(session.schedule).getTime(), identity, withTimeZone, withYearInfo);
    }

    static getLocalizedDateFormatByTimestamp(timestamp?: number,
                                             identity?: AccountIdentity,
                                             withTimeZone: boolean = true,
                                             withYearInfo: boolean = false): string {
        if (!timestamp) {
            return "";
        }

        const getLocaleString = (locale: string) => {
            const LOOKUP = {
                ja: "M月 d日 (EEEE) @ H:mm",
                ko: "EEEE, MMMM dd, HH:mm",
                en: "EEEE, MMMM dd, HH:mm"
            };
            return LOOKUP[locale] ?? LOOKUP[LANGUAGE_DEFAULT];
        };

        const getLocaleStringWithYearInfo = (locale: string) => {
            const LOOKUP = {
                ja: "Y年 M月 d日 (EEEE) @ H:mm",
                ko: "EEEE, MMMM dd yyyy, HH:mm",
                en: "EEEE, MMMM dd yyyy, HH:mm"
            };
            return LOOKUP[locale] ?? LOOKUP[LANGUAGE_DEFAULT];
        };

        const locale = DateFnsConfig.getLocale();
        let localeString = withYearInfo ? getLocaleStringWithYearInfo(locale?.code) : getLocaleString(locale?.code);
        let dateString = fnsFormat(new Date(timestamp), localeString, { locale: locale });

        return withTimeZone
            ? `${dateString} (GMT ${fnsFormat(new Date(timestamp), "xxx")})`
            : dateString;
    }
}

export const getSessionTypeIdByActivityTypeId = (activityTypeId?: number): number | undefined => {
    const LOOKUP = {
        [ActivityV1.ACTIVITY_TYPE_ID_GOLIVE]: SessionType.TYPE_VL_ID,
        [ActivityV1.ACTIVITY_TYPE_ID_LT]: SessionType.TYPE_LT_ID,
        [ActivityV1.ACTIVITY_TYPE_ID_OT]: SessionType.TYPE_OT_ID,
        [ActivityV1.ACTIVITY_TYPE_ID_LUT]: SessionType.TYPE_LUT_ID,
        [ActivityV1.ACTIVITY_TYPE_ID_MATERIAL_LESSON]: SessionType.TYPE_ML_ID
    };
    return LOOKUP[activityTypeId];
};

export const getActivityTypeIdBySessionTypeId = (activityTypeId?: number): number | undefined => {
    const LOOKUP = {
        [SessionType.TYPE_VL_ID]: ActivityV1.ACTIVITY_TYPE_ID_GOLIVE,
        [SessionType.TYPE_LT_ID]: ActivityV1.ACTIVITY_TYPE_ID_LT,
        [SessionType.TYPE_OT_ID]: ActivityV1.ACTIVITY_TYPE_ID_OT,
        [SessionType.TYPE_LUT_ID]: ActivityV1.ACTIVITY_TYPE_ID_LUT,
        [SessionType.TYPE_ML_ID]: ActivityV1.ACTIVITY_TYPE_ID_MATERIAL_LESSON
    };
    return LOOKUP[activityTypeId];
};

export const getClassRoomUrl = (sessionId: number): string => {
    return `/${ROUTE_MEETING_ROOM}?sessionId=${sessionId}`;
};

export const getEmbeddedZoomUrl = (sessionId: number): string => {
    return `${ROUTE_RTC_CLASSROOM}/${sessionId}`;
};

export const getAgoraClassRoomUrl = (channel: string, jwtToken: string, username?: string): string => {
    const urlParams = new URLSearchParams();
    urlParams.set("token", jwtToken);
    if (username) {
        urlParams.set("name", username);
    }
    return `/${ROUTE_AGORA_MEETING}/${channel}?${urlParams}`;
};

export const getAgoraCmsClassRoomUrl = (channel: string, jwtToken: string, username?: string, sessionId?: number, recordingEnabled: boolean = false): string => {
    const urlParams = new URLSearchParams();
    urlParams.set("token", jwtToken);
    if (username) {
        urlParams.set("name", username);
    }
    if (sessionId) {
        urlParams.set("sessionId", `${sessionId}`);
    }
    if (recordingEnabled) {
        urlParams.set("recordingEnabled", "true");
    }

    return `/v2/${ROUTE_AGORA_MEETING}/${channel}?${urlParams}`;
};

export const getDefaultGetSessionPeriod = (startDate = new Date(), daysinFuture: number = 365): Record<string, string> => {
    return {
        fromScheduleDate: startDate.toISOString(),
        toScheduleDate: addDays(startDate, daysinFuture).toISOString()
    };
};
